// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade-in {
  opacity: 0;
  transform: translateY(10px);
  animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.polling-option.active {
  background-color: rgba(227, 166, 22, 1);
  color: #fff;
}

.infinite-scroll-component {
  overflow: hidden !important; /* Prevent overflow */
}

.question_link{
color: #fafafa;  
}`, "",{"version":3,"sources":["webpack://./src/components/style/EventsSection.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,2BAA2B;EAC3B,2CAA2C;AAC7C;;AAEA;EACE;IACE,UAAU;IACV,wBAAwB;EAC1B;AACF;;AAEA;EACE,uCAAuC;EACvC,WAAW;AACb;;AAEA;EACE,2BAA2B,EAAE,qBAAqB;AACpD;;AAEA;AACA,cAAc;AACd","sourcesContent":[".fade-in {\r\n  opacity: 0;\r\n  transform: translateY(10px);\r\n  animation: fadeIn 0.5s ease-in-out forwards;\r\n}\r\n\r\n@keyframes fadeIn {\r\n  to {\r\n    opacity: 1;\r\n    transform: translateY(0);\r\n  }\r\n}\r\n\r\n.polling-option.active {\r\n  background-color: rgba(227, 166, 22, 1);\r\n  color: #fff;\r\n}\r\n\r\n.infinite-scroll-component {\r\n  overflow: hidden !important; /* Prevent overflow */\r\n}\r\n\r\n.question_link{\r\ncolor: #fafafa;  \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
