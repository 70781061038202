import Cookies from 'js-cookie';

export async function authenticate() {
  const email = process.env.REACT_APP_ADMIN_EMAIL;
  const password = process.env.REACT_APP_ADMIN_PASSWORD;

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/v1/admin/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password }),
      credentials: 'include', // Allow backend to set HttpOnly cookie
    });

    const data = await response.json();

    if (data.status === 1) {
      console.log('Login successful');
      // The backend will set an HttpOnly cookie automatically; no need to set manually.
      return data.user; // Returning user data if you need it
    } else {
      console.error('Authentication failed:', data.message);
      return null;
    }
  } catch (error) {
    console.error('Error during authentication:', error);
    return null;
  }
}
