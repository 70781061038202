import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import "./style/OrderWidget.css";

const OrderWidget = ({ event, onClose, selectedOption, selectedYesNo }) => {
  const { accessToken, fetchUserBalance } = useAuth();
  const [animationClass, setAnimationClass] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  // ✅ Set bid type based on selectedYesNo (Yes or No button clicked)
  const [bidType, setBidType] = useState(selectedYesNo || "yes");

  // ✅ Set initial price based on selected option or event values
  const [price, setPrice] = useState(
    selectedYesNo === "no" 
      ? Number(selectedOption?.no_point || event?.no_point || 6.5) 
      : Number(selectedOption?.yes_point || event?.yes_point || 6.5)
  );

  // ✅ Dynamic update for "You Put" and "You Get"
  const [youPut, setYouPut] = useState(price * quantity);
  const [youGet, setYouGet] = useState(quantity * 10);

  useEffect(() => {
    setYouPut(price * quantity);
    setYouGet(quantity * 10);
  }, [price, quantity]);

  useEffect(() => {
    if (event) {
      setAnimationClass("show");
    } else {
      setAnimationClass("hide");
    }
  }, [event]);

  const handleClose = () => {
    setAnimationClass("hide");
    setTimeout(() => {
      onClose();
    }, 400);
  };

  const incrementQuantity = () => {
    setQuantity((prev) => (prev < 5 ? prev + 1 : prev));
  };

  const decrementQuantity = () => {
    setQuantity((prev) => (prev > 1 ? prev - 1 : prev));
  };

  // ✅ Handle Yes/No selection & update price accordingly
  const handleBidTypeChange = (type) => {
    setBidType(type);
    const newPrice = type === "no"
      ? Number(selectedOption?.no_point || event?.no_point || 6.5)
      : Number(selectedOption?.yes_point || event?.yes_point || 6.5);
    
    setPrice(newPrice);
  };

  // ✅ **Bid Placement API Call**
  const placeOrder = async () => {
    if (!event) {
      setMessage("Invalid order details");
      setMessageType("danger");
      return;
    }

    if (!accessToken) {
      setMessage("Authorization token is missing.");
      setMessageType("danger");
      return;
    }

    setLoading(true);
    setMessage("");
    setMessageType("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/bids/place`,
        {
          questionId: event.id,
          price: price,
          quantity: quantity,
          bidType: bidType, // ✅ Ensure correct value is sent
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          withCredentials: true,
        }
      );

      if (response.status === 201 || response.status === 200) {
        setMessage("Bid placed successfully!");
        setMessageType("success"); // ✅ Set success type
        await fetchUserBalance(accessToken);
      } else {
        setMessage(response.data.message || "Failed to place bid.");
        setMessageType("danger"); // ❌ Set error type
      }
    } catch (error) {
      console.error("❌ API Error:", error);
      setMessage(error.response?.data?.message || "Failed to place bid.");
      setMessageType("danger"); // ❌ Set error type
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Overlay */}
      <div className={`overlay ${event ? "show" : "hide"}`} onClick={handleClose}></div>

      {/* Right Panel */}
      <div className={`order-widget ${animationClass}`}>
        <span className="close-btn" onClick={handleClose}>
          <i className="fa fa-times"></i>
        </span>

        {/* ✅ Display Question */}
        <h3 className="widget-question">{event?.question || "No Question Available"}</h3>

        {/* ✅ Show selected polling option inside widget */}
        {selectedOption && (
          <div className="selected-option">
            <p>
              <strong>Selected Option:</strong> {selectedOption.options_str}
            </p>
          </div>
        )}

        {/* ✅ Toggle Buttons for Yes/No with Correct Values */}
        <div className="btn-group d-flex gap-3 mt-3" role="group">
          <button
            className={`btn ${bidType === "yes" ? "btn-yes active" : "btn-yes"}`}
            onClick={() => handleBidTypeChange("yes")}
          >
            Yes ₹{selectedOption?.yes_point || event?.yes_point}
          </button>
          <button
            className={`btn ${bidType === "no" ? "btn-no active" : "btn-no"}`}
            onClick={() => handleBidTypeChange("no")}
          >
            No ₹{selectedOption?.no_point || event?.no_point}
          </button>
        </div>

        {/* ✅ Price Section (Now Read-Only) */}
        <div className="price-section">
          <div className="price-label">Price</div>
          <div className="d-flex flex-column">
            <div className="price-controls">
              <input
                type="number"
                className="price-value"
                value={price}
                readOnly
              />
            </div>
          </div>
        </div>

        {/* ✅ Quantity Section */}
        <div className="quantity-section">
          <div className="quantity-label">Quantity</div>
          <div className="quantity-controls">
            <button className="decrement" onClick={decrementQuantity}>
              -
            </button>
            <span className="quantity-value">{quantity}</span>
            <button className="increment" onClick={incrementQuantity}>
              +
            </button>
          </div>
        </div>

        {/* ✅ Total Section */}
        <div className="total-section">
          <div className="you-put">
            ₹{youPut.toFixed(1)}
            <br />
            <span>You put</span>
          </div>
          <div className="you-get">
            ₹{youGet.toFixed(1)}
            <br />
            <span>You get</span>
          </div>
        </div>

        {/* ✅ Place Order Button */}
        {/* <button className="place-order" onClick={placeOrder} disabled={loading}>
          {loading ? "Placing Order..." : "Place Order"}
        </button> */}


        {/* ✅ Show Loader When Placing Order */}
        {loading ? (
          <p style={{ textAlign: "center" }}>
            <span className="loader"></span>
          </p>
        ) : (
          <button className="place-order" onClick={placeOrder} disabled={loading}>
            Place Order
          </button>
        )}


      {/* ✅ Bootstrap Alert for Success or Error Messages */}
      {message && (
        <div className={`alert ${messageType === "success" ? "alert-success" : "alert-danger"} mt-3`} role="alert">
          {message}
        </div>
      )}
      </div>
    </>
  );
};

export default OrderWidget;
