import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageBreadcrumb from '../components/PageBreadcrumb';
import MyWallet from '../components/MyWallet';


const Wallet = () => {
  return (
    <div>
      <Helmet>
        <title>Prediction API - Wallet</title>
        <meta name="description" content="View the details of a single trade on Prediction API." />
      </Helmet>
      <PageBreadcrumb pageName="Wallet" />
       <MyWallet />
    </div>
  );
};

export default Wallet;
