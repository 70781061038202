import React, { useEffect } from 'react';
import { Routes, useLocation } from 'react-router-dom';
import SiteRoutes from './routes/SiteRoutes';
import AuthRoutes from './routes/AuthRoutes';

const App = () => {
  const location = useLocation(); 

  useEffect(() => {
    // ✅ Save last visited page (except login/OTP pages)
    if (!["/login", "/otpverification"].includes(location.pathname)) {
      sessionStorage.setItem("lastVisitedPage", location.pathname);
    }
  }, [location.pathname]);

  return (
    <Routes>
      {SiteRoutes()}
      {AuthRoutes()}
    </Routes>
  );
};

export default App;
