import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

const BreadcrumbSection = () => {
  const { id } = useParams();
  const [category, setCategory] = useState(null);

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/v1/admin/getSubcategoryById/${id}`, {
          withCredentials: true,
        });
        if (response.data.status === 1) {
          setCategory(response.data.subcategory);
        } else {
          console.error('Failed to fetch category details');
        }
      } catch (error) {
        console.error('Error fetching category details:', error);
      }
    };

    fetchCategoryDetails();
  }, [id]);

  return (
    <section className="breadcrumb-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="category-title d-flex align-items-center justify-content-center gap-3 mb-3">
              <div className="match-img">
                <img src="/assets/images/card-thumb2.png" className="img-fluid" alt="Match Thumbnail" />
              </div>
              <div className="match-details">
                <h1 className="match-info">
                  {category ? category.subcategory : 'Loading...'}
                </h1>
              </div>
            </div>
            <nav style={{ "--bs-breadcrumb-divider": "'>'" }} aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">
                  {category ? category.subcategory : 'Library'}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BreadcrumbSection;
