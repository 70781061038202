import React from 'react';
import { Helmet } from 'react-helmet-async';
import BreadcrumbSection from '../components/BreadcrumbSection';
import CategoryTabs from '../components/CategoryTabs';
const Category = () => {
  return (
    <div>
      <Helmet>
        <title>Prediction API - Category</title>
        <meta name="description" content="Explore various categories of events in the Prediction API." />
      </Helmet>
      <BreadcrumbSection />
      <CategoryTabs />
    </div>
  );
};

export default Category;
