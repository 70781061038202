import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../context/AuthContext";

const TransactionTable = ({ activeTab, activeSubTab }) => {
  const { accessToken } = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/transactions/history`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: {
            type: activeTab,
            subType: activeSubTab,
          },
          withCredentials: true,
        }
      );

      if (response.data && response.data.transactions) {
        setTransactions(response.data.transactions);
      } else {
        setTransactions([]);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setTransactions([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [activeTab, activeSubTab]);

  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Transactions</th>
            <th>Order ID</th>
            <th>Status</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="4" className="text-center">Loading...</td>
            </tr>
          ) : transactions.length > 0 ? (
            transactions.map((transaction, index) => (
              <tr key={index}>
                <td>
                  <div className="h6">{transaction.transaction_type}</div>
                  <div>{transaction.details}</div>
                  <small>{new Date(transaction.created_at).toLocaleString()}</small>
                </td>
                <td>{transaction.order_id || "-"}</td>
                <td><span className="text-success">{transaction.status}</span></td>
                <td><span className={`text-${transaction.amount < 0 ? 'danger' : 'success'}`}>₹{transaction.amount}</span></td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">No transactions found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionTable;
