import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import TransactionBreadcrumbSection from '../components/TransactionBreadcrumbSection';
import TransactionTabs from '../components/TransactionTabs';
import TransactionTable from '../components/TransactionTable';

const TransactionHistory = () => {
  const [activeTab, setActiveTab] = useState('Balance');
  const [activeSubTab, setActiveSubTab] = useState('All');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setActiveSubTab('All'); // Reset sub-tab on main tab change
  };

  const handleSubTabChange = (subTab) => {
    setActiveSubTab(subTab);
  };

  return (
    <>
    <TransactionBreadcrumbSection />
    <div className="container mt-5">
      <div className="row">
        <div className="d-flex justify-content-between">
          <h2 className="mb-0">Transaction History</h2>
          <TransactionTabs
            tabs={['Balance', 'Recharge', 'Withdraw']}
            activeTab={activeTab}
            onTabChange={handleTabChange}
          />
        </div>
        <div className="tab-content mt-3">
          {activeTab === 'Balance' && (
            <TransactionTabs
              tabs={['All', 'Credit', 'Debit']}
              activeTab={activeSubTab}
              onTabChange={handleSubTabChange}
            />
          )}
          <TransactionTable activeTab={activeTab} activeSubTab={activeSubTab} />
        </div>
      </div>
    </div>
  </>
  );
};

export default TransactionHistory;
