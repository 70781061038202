import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../context/AuthContext"; // ✅ Import AuthContext

const EventTradeList = ({ eventId }) => {
  const { accessToken } = useAuth(); // ✅ Get token from AuthContext
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [trades, setTrades] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchTrades();
  }, [eventId]);

  const fetchTrades = async () => {
    if (!accessToken) {
      setMessage("Authorization token is missing.");
      return;
    }

    setLoading(true);
    setMessage("");

    try {
      const response = await axios.get(
        `${serverUrl}/bids/userbids?status=accepted,completed,canceled`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          withCredentials: true,
        }
      );

      console.log("✅ API Response:", response);

      if (response.status === 200) {
        setTrades(response.data.bids.filter((bid) => bid.question_id === Number(eventId)));
      } else {
        setMessage(response.data.message || "Failed to fetch trades.");
      }
    } catch (error) {
      console.error("❌ API Error:", error);
      setMessage(error.response?.data?.message || "Failed to fetch trades.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="card shadow-sm p-3">
      <h4 className="fw-bold text-center">Trades</h4>
      {message && <p className="text-center text-danger">{message}</p>}
      {loading ? (
        <div className="text-center">
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      ) : trades.length > 0 ? (
        <table className="table table-striped mt-3">
          <thead className="text-center">
            <tr>
              <th>Trade ID</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Type</th>
              <th>Expected Payout</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {trades.map((trade) => (
              <tr key={trade.id} className="text-center">
                <td>{trade.id}</td>
                <td>₹{trade.price ? parseFloat(trade.price).toFixed(2) : "0.00"}</td>
                <td>{trade.quantity}</td>
                <td>{trade.bid_type}</td>
                <td className={trade.expected_payout >= 0 ? "text-success" : "text-danger"}>
                  ₹{trade.expected_payout ? parseFloat(trade.expected_payout).toFixed(2) : "0.00"}
                </td>
                <td>
                  <span className={`badge bg-${trade.status === "accepted" ? "success" : trade.status === "completed" ? "primary" : "danger"}`}>
                    {trade.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-muted">No trades found for this event.</p>
      )}
    </div>
  );
};

export default EventTradeList;
