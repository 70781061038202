import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import "bootstrap/dist/css/bootstrap.min.css";

const Recharge = () => {
  const { accessToken, fetchUserBalance } = useAuth(); // Get access token & fetch balance function
  const [amount, setAmount] = useState(250);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const GST_RATE = 0.28;
  const gstAmount = parseFloat((amount * GST_RATE).toFixed(2));
  const depositBalance = parseFloat((amount - gstAmount).toFixed(2));
  const cashback = gstAmount;
  const netBalance = amount;

  // **Validate Input**
  const validateAmount = () => {
    if (!amount || amount < 1) {
      setError("Please enter a valid amount.");
      return false;
    }
    return true;
  };

  // **Handle Recharge**
  const handleRecharge = async () => {
    if (!validateAmount()) return;

    setLoading(true);
    setError("");
    setMessage("");

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/transactions/recharge`,
        { amount, gstAmount, depositBalance, cashback },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          withCredentials: true,
        }
      );

      setMessage("Recharge successful! Transaction saved.");
      setAmount(250);

      // ✅ Fetch updated balance
      await fetchUserBalance(accessToken);
    } catch (error) {
      setError(error.response?.data?.message || "Failed to process recharge.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row recharge_holder">
        {/* Left Side - Deposit Form */}
        <div className="col-md-6">
          <div className="card shadow-sm">
            <div className="card-body">
              <h4 className="card-title fw-bold">Deposit</h4>

              {error && <p className="text-danger text-center">{error}</p>}
              {message && <p className="text-success text-center">{message}</p>}

              <label className="form-label">Deposit amount</label>
              <input
                type="number"
                className="form-control"
                value={amount}
                onChange={(e) => setAmount(Number(e.target.value))}
                min="1"
              />
              <div className="btn-group mt-3" role="group">
                <button className="btn btn-outline-secondary select_btn" onClick={() => setAmount(amount + 250)}>+250</button>
                <button className="btn btn-outline-secondary select_btn" onClick={() => setAmount(amount + 500)}>+500</button>
                <button className="btn btn-outline-secondary select_btn" onClick={() => setAmount(amount + 1000)}>+1000</button>
              </div>
              <button className="btn btn-dark w-100 mt-3" onClick={handleRecharge} disabled={loading}>
                {loading ? "Processing..." : "Recharge"}
              </button>
            </div>
          </div>
        </div>

        {/* Right Side - Summary Panel */}
        <div className="col-md-6">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="text-uppercase fw-bold text-muted border-bottom pb-2">
                Summary
              </h5>
              <div className="d-flex justify-content-between">
                <span>Recharge amount:</span>
                <span className="fw-bold">₹{amount.toFixed(2)}</span>
              </div>
              <div className="d-flex justify-content-between text-danger">
                <span>GST applicable:</span>
                <span>- ₹{gstAmount.toFixed(2)}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>Deposit bal. credit:</span>
                <span className="fw-bold">₹{depositBalance.toFixed(2)}</span>
              </div>
              <div className="d-flex justify-content-between text-success">
                <span>Promotional bal. credit:</span>
                <span>+ ₹{cashback.toFixed(2)}</span>
              </div>
              <div className="d-flex justify-content-between mt-3 border-top pt-2 fw-bold">
                <span className="fs-5">Net Balance</span>
                <span className="fs-5">₹{netBalance.toFixed(2)}</span>
              </div>
              <p className="text-success mt-2">
                <i className="bi bi-gift-fill"></i> Recharge Cashback
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recharge;
