import React, { useEffect, useState } from 'react';
import { authenticate } from '../utils/auth';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

const EventsSection = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      const token = await authenticate();
      if (token) {
        try {
          const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/v1/admin/getAllQuestions`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${Cookies.get('auth_token')}`,
            },
            credentials: 'include',
          });

          const data = await response.json();
          if (data.status === 1) {
            setEvents(data.questions);
          } else {
            console.error('Failed to fetch events:', data.message);
          }
        } catch (error) {
          console.error('Error fetching events:', error);
        }
      } else {
        console.error('Authentication failed');
      }
      setLoading(false);
    };
    fetchEvents();
  }, []);

  return (
    <section className="content-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="section-title">All events</h3>
            <hr />
            {loading ? (
              <p>Loading events...</p>
            ) : events.length > 0 ? (
              <div className="row">
                {events.map((event) => (
                  <div className="col-md-4 mb-4" key={event.id}>
                    <div className="card events-card">
                      <div className="card-header gap-2">
                        <i className="fa fa-users"></i>
                        <span>{event.traders_count} traders</span>
                      </div>
                      <div className="card-body">
                        <div className="d-flex gap-3">
                          <div className="match-img">
                            <img src="assets/images/card-thumb.png" className="img-fluid" alt="Match Thumbnail" />
                          </div>
                          <div className="card-details">
                            <p className="match-info">{event.question}</p>
                          </div>
                        </div>
                        <p className="head-to-head">{event.head_to_head}</p>
                        <div className="btn-group d-flex gap-3" role="group">
                          <Link to={`/singletrade/${event.id}`} className="btn btn-yes">{event.yes_point}</Link>
                          <Link to={`/singletrade/${event.id}`} className="btn btn-no">{event.no_point}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>Events not found.</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventsSection;
