import React from 'react';

const Footer = () => (
  <footer>
    <div className="container">
      <div className="row py-5">
        <div className="col-md-3">
          <h4 className="widget-title">Company</h4>
          <ul className="footer-links">
            <li><a href="#">About us</a></li>
            <li><a href="#">Culture</a></li>
          </ul>
        </div>
        <div className="col-md-3">
          <h4 className="widget-title">Resources</h4>
          <ul className="footer-links">
            <li><a href="#">Help Center</a></li>
            <li><a href="#">Contact Support</a></li>
            <li><a href="#">What's New</a></li>
          </ul>
        </div>
        <div className="col-md-3">
          <h4 className="widget-title">Careers</h4>
          <ul className="footer-links">
            <li><a href="#">Open Roles</a></li>
          </ul>
        </div>
        <div className="col-md-3">
          <h4 className="widget-title">Contact Us</h4>
          <ul className="footer-links">
            <li><a href="#">help@prediction.in</a></li>
            <li><a href="#">communication@prediction.in</a></li>
          </ul>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12">
          <div className="footer-social">
            <a href="#" title="Facebook"><i className="fab fa-facebook-f fa-fw"></i></a>
            <a href="#" title="YouTube"><i className="fab fa-youtube fa-fw"></i></a>
            <a href="#" title="Twitter"><i className="fab fa-twitter fa-fw"></i></a>
            <a href="#" title="Instagram"><i className="fab fa-instagram"></i></a>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12">
          <p className="text-center text-white">© 2025 by Prediction API</p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
