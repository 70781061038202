import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const MenuSlider = () => {
  const [subcategories, setSubcategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/v1/getSubcategoriesWithLiveQuestions`, {
          method: 'GET',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();

        if (data.status === 1 && Array.isArray(data.subcategories)) {
          setSubcategories(data.subcategories);
        } else {
          setError('Failed to fetch subcategories or data format is incorrect');
        }
      } catch (fetchError) {
        setError('Error fetching subcategories');
        console.error('Fetch error:', fetchError);
      } finally {
        setLoading(false);
      }
    };

    fetchSubcategories();
  }, []);

  const scrollTabsLeft = () => {
    document.querySelector('.tabs-wrapper').scrollBy({ left: -100, behavior: 'smooth' });
  };

  const scrollTabsRight = () => {
    document.querySelector('.tabs-wrapper').scrollBy({ left: 100, behavior: 'smooth' });
  };

  const handleTabClick = (subcategoryId) => {
    navigate(`/category/${subcategoryId}`); // Navigate to the respective route
  };

  if (loading) {
    return <p style={{ textAlign: 'center' }}><span className="loader"></span></p>;
  }

  if (error) {
    return <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{error}</p>;
  }

  return (
    <section className="menu-slider-sec">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="tabs-container">
              <button className="scroll-btn left" onClick={scrollTabsLeft}>&#x276E;</button>
              <div className="tabs-wrapper">
                <ul className="tabs">
                  {subcategories.length > 0 ? (
                    subcategories.map((subcategory) => (
                      <li 
                        className="tab" 
                        key={subcategory.id} 
                        onClick={() => handleTabClick(subcategory.id)} // Navigate on click
                        style={{ cursor: 'pointer' }}
                      >
                        <img 
                          src="assets/images/category1.jpeg" 
                          width="24px" 
                          height="24px" 
                          className="rounded" 
                          alt={subcategory.subcategory} 
                        />
                        {subcategory.subcategory}
                      </li>
                    ))
                  ) : (
                    <p style={{ textAlign: 'center' }}>No subcategories available</p>
                  )}
                </ul>
              </div>
              <button className="scroll-btn right" onClick={scrollTabsRight}>&#x276F;</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MenuSlider;
