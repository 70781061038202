import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../context/AuthContext"; // ✅ Import AuthContext
import EventSummary from "../components/EventSummary";
import EventTradeList from "../components/EventTradeList";

const EventPortfolioPage = () => {
  const { eventId } = useParams(); // Extract event ID from route params
  const { accessToken } = useAuth(); // ✅ Get access token from AuthContext
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchEventDetails();
  }, [eventId]);

  const fetchEventDetails = async () => {
    if (!accessToken) {
      setMessage("Authorization token is missing.");
      return;
    }

    setLoading(true);
    setMessage("");

    try {
      const response = await axios.get(
        `${serverUrl}/v1/getQuestionById/${eventId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          withCredentials: true,
        }
      );

      console.log("✅ API Response:", response);

      if (response.status === 200 && response.data.status === 1) {
        setEventData(response.data.question);
      } else {
        setMessage(response.data.message || "Failed to fetch event details.");
      }
    } catch (error) {
      console.error("❌ API Error:", error);
      setMessage(error.response?.data?.message || "Failed to fetch event details.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
        <div className="eventportfoliopage">
      {message && <p className="text-center text-danger">{message}</p>}
      {loading ? (
        <div className="text-center">
          <div className="spinner-border text-primary" role="status"></div>
          <p className="mt-2">Loading event details...</p>
        </div>
      ) : (
        <>
          {/* Event Summary Section */}
          <EventSummary eventData={eventData} />

          {/* Event Trade List Section */}
          <EventTradeList eventId={eventId} />
        </>
      )}
      </div>
    </div>
  );
};

export default EventPortfolioPage;
