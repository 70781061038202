import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';

const OTPVerificationForm = () => {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [timer, setTimer] = useState(300); // 5 minutes in seconds

  const location = useLocation();
  const navigate = useNavigate();
  const { login } = useAuth(); // Use AuthContext to store tokens

  const { mobile, from } = location.state || {}; // Preserve 'from' location

  useEffect(() => {
    if (!mobile) {
      navigate('/login', { replace: true });
    }
  }, [mobile, navigate]);

  useEffect(() => {
    if (resendDisabled) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setResendDisabled(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [resendDisabled]);

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value !== '' && index < otp.length - 1) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      document.getElementById(`otp-${index - 1}`).focus();
    }
  };

  const verifyOTP = async () => {
    const enteredOtp = otp.join('');
    if (enteredOtp.length !== 6) {
      setError('Please enter all 6 digits of the OTP.');
      return;
    }

    setLoading(true);
    setError('');
    setMessage('');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/verify-otp`,
        { mobile, otp: enteredOtp },
        { withCredentials: true }
      );

      if (response.data.accessToken) {
        setMessage('OTP Verified Successfully!');
        login(response.data.accessToken, response.data.user); // Store token and user in AuthContext

        // Redirect to the original page or profile
        navigate(from || '/profile', { replace: true });
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to verify OTP.');
    } finally {
      setLoading(false);
    }
  };

  const resendOTP = async () => {
    setLoading(true);
    setError('');
    setMessage('');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/send-otp`,
        { mobile }
      );

      if (response.data.message === 'OTP sent successfully') {
        setMessage('A new OTP has been sent to your mobile.');
        setResendDisabled(true);
        setTimer(300); // Reset timer
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to resend OTP.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="vh-100" style={{ backgroundColor: '#000' }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-xl-10">
            <div className="card" style={{ borderRadius: '1rem' }}>
              <div className="row g-0">
                <div className="col-md-6 col-lg-5 d-none d-md-block">
                  <img
                    src="assets/images/img1.webp"
                    alt="OTP Verification"
                    className="img-fluid"
                    style={{ borderRadius: '1rem 0 0 1rem' }}
                  />
                </div>
                <div className="col-md-6 col-lg-7 d-flex align-items-center">
                  <div className="card-body p-4 p-lg-5 text-black">
                    {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
                    {message && <p style={{ color: 'green', textAlign: 'center' }}>{message}</p>}
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        verifyOTP();
                      }}
                    >
                      <div className="d-flex align-items-center mb-4 pb-1">
                        <i
                          className="fas fa-cubes fa-2x me-3"
                          style={{ color: 'rgba(227, 166, 22, 1)' }}
                        ></i>
                        <span className="h2 fw-bold mb-0">Prediction API</span>
                      </div>

                      <h4 className="fw-bold mb-0 pb-3" style={{ letterSpacing: '1px' }}>
                        Verify phone number
                      </h4>
                      <p>OTP has been sent to {mobile ? `+91 ${mobile}` : 'your mobile number'}.</p>

                      <div className="d-flex mb-4">
                        {otp.map((_, index) => (
                          <input
                            key={index}
                            type="text"
                            maxLength="1"
                            className="otp-input"
                            id={`otp-${index}`}
                            value={otp[index]}
                            onChange={(e) => handleInputChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                          />
                        ))}
                      </div>

                      <p className="mb-3 pb-lg-2 text-center" style={{ color: '#999999' }}>
                        Didn't receive the OTP?{' '}
                        <button
                          type="button"
                          className="btn btn-link p-0"
                          onClick={resendOTP}
                          disabled={resendDisabled}
                          style={{
                            color: resendDisabled ? '#999999' : 'rgba(227, 166, 22, 1)',
                            cursor: resendDisabled ? 'not-allowed' : 'pointer',
                          }}
                        >
                          Resend OTP
                        </button>
                        {resendDisabled && (
                          <span style={{ color: '#999999', marginLeft: '10px' }}>
                            ({Math.floor(timer / 60)}:{timer % 60 < 10 ? '0' : ''}{timer % 60})
                          </span>
                        )}
                      </p>

                      <div className="pt-1 mb-4">
                        <button
                          type="submit"
                          className="btn btn-dark btn-lg btn-block w-100"
                          disabled={loading}
                        >
                          {loading ? 'Verifying...' : 'Verify OTP'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OTPVerificationForm;
