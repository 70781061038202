import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SiteLayout from './layouts/SiteLayout';
import HomePage from './pages/HomePage';
import Category from './pages/Category';
import SingleTrade from './pages/SingleTrade';
const App = () => {
  return (
    <Router>
      <SiteLayout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/category/:id" element={<Category />} />
          <Route path="/singletrade/:id" element={<SingleTrade />} />
        </Routes>
      </SiteLayout>
    </Router>
  );
};

export default App;
