import React from "react";

const PortfolioSummary = () => {
  return (
    <div className="card mt-3 shadow-sm p-3">
      <div className="row text-center">
        <div className="col">
          <span className="text-muted">RETURNS</span>
          <h2 className="text-danger">- ₹90</h2>
        </div>
        <div className="col">
          <span className="text-muted">Investment</span>
          <h3>₹305</h3>
        </div>
        <div className="col">
          <span className="text-muted">Today's Returns</span>
          <h3>₹0</h3>
        </div>
        <div className="col">
          <span className="text-muted">Rank</span>
          <h3>28341646</h3>
        </div>
      </div>
    </div>
  );
};

export default PortfolioSummary;
