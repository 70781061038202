import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import OrderWidget from './OrderWidget'; // ✅ Import OrderWidget
import { useAuth } from '../context/AuthContext'; // ✅ Import authentication context

const CategoryTabs = () => {
  const { id: subcategoryId } = useParams();
  const { accessToken } = useAuth(); // ✅ Check if user is logged in
  const navigate = useNavigate();
  const [filter, setFilter] = useState('All');
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      setFadeIn(false);

      let response;
      if (filter === 'All') {
        response = await axios.get(`${serverUrl}/v1/getLiveQuestionsBySubcategory/${subcategoryId}`, {
          withCredentials: true,
        });
      } else {
        response = await axios.get(`${serverUrl}/v1/fetchQuestionsBySubcategoryFilter/${subcategoryId}`, {
          params: { filter: filter.toLowerCase(), page: 1, perPage: 10 },
          withCredentials: true,
        });
      }

      const data = response.data.questions || [];
      const defaultOptions = {};
      data.forEach((question) => {
        if (question.polling === 2 && question.options?.length > 0) {
          defaultOptions[question.id] = question.options[0].id;
        }
      });

      setSelectedOptions(defaultOptions);
      setQuestions(data);
    } catch (error) {
      console.error('Error fetching questions:', error);
    } finally {
      setTimeout(() => {
        setLoading(false);
        setTimeout(() => setFadeIn(true), 100);
      }, 2000);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, [filter, subcategoryId, serverUrl]);

  const handleOptionClick = (questionId, optionId) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [questionId]: optionId,
    }));
  };

  const handleYesNoClick = (question, selectedYesNo) => {
    if (!accessToken) {
      // ✅ If user is not logged in, redirect to `SingleTrade`
      navigate(`/singletrade/${question.id}`);
      return;
    }

    // ✅ If user is logged in, show `OrderWidget`
    const selectedOption = question.polling === 2
      ? question.options?.find(option => option.id === selectedOptions[question.id])
      : null;

    setSelectedQuestion({ 
      ...question, 
      selectedOption, 
      selectedYesNo // ✅ Store which option (Yes/No) was clicked
    });
  };

  return (
    <section className="content-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ul className="nav custom-nav-rounded justify-content-center mb-5">
              <li className="nav-item">
                <button className={`nav-link ${filter === 'All' ? 'active' : ''}`} onClick={() => setFilter('All')}>
                  All
                </button>
              </li>
              <li className="nav-item">
                <button className={`nav-link ${filter === 'Match' ? 'active' : ''}`} onClick={() => setFilter('Match')}>
                  <i className="fa fa-trophy mr-2"></i> Match
                </button>
              </li>
              <li className="nav-item">
                <button className={`nav-link ${filter === 'Over' ? 'active' : ''}`} onClick={() => setFilter('Over')}>
                  <i className="fa fa-bowling-ball mr-2"></i> Over
                </button>
              </li>
            </ul>

            <div className="tab-content">
              {loading ? (
                <p style={{ textAlign: 'center', animation: 'fadeInOut 2s ease-in-out' }}>
                  <span className="loader"></span>
                </p>
              ) : questions.length > 0 ? (
                <div
                  className={`row ${fadeIn ? 'fade-in' : ''}`}
                  style={{ opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease-in-out' }}
                >
                  {questions.map((question) => {
                    const selectedOption = question.polling === 2
                      ? question.options?.find(option => option.id === selectedOptions[question.id])
                      : null;

                    return (
                      <div className="col-md-4 mb-4" key={question.id}>
                        <div className="card events-card">
                          <div className="card-header gap-2">
                            <i className="fa fa-users"></i>
                            <span>{question.trader_count || 0} traders</span>
                          </div>
                          <div className="card-body">
                            <div className="d-flex gap-3">
                              <div className="match-img">
                                <img src="/assets/images/card-thumb.png" className="img-fluid" alt="Event Thumbnail" />
                              </div>
                              <div className="card-details">
                                <p className="match-info">
                                  <Link to={`/singletrade/${question.id}`} className="question_link">
                                    {question.question}
                                  </Link>
                                </p>
                                <p style={{ fontSize: '12px', color: '#B7B8B6' }}>{question.description}</p>
                              </div>
                            </div>

                            {/* ✅ Polling Question Options */}
                            {question.polling === 2 && question.options?.length > 0 && (
                              <div className="polling-options d-flex gap-2 mt-3">
                                {question.options.map((option) => (
                                  <span
                                    key={option.id}
                                    onClick={() => handleOptionClick(question.id, option.id)}
                                    className={`polling-option ${selectedOptions[question.id] === option.id ? 'active' : ''}`}
                                    style={{
                                      cursor: 'pointer',
                                      padding: '5px 10px',
                                      border: '1px solid rgba(227, 166, 22, 1)',
                                      borderRadius: '5px',
                                      fontSize: '12.5px',
                                      backgroundColor: selectedOptions[question.id] === option.id
                                        ? 'rgba(227, 166, 22, 1)'
                                        : 'transparent',
                                      color: '#fff',
                                    }}
                                  >
                                    {option.options_str}
                                  </span>
                                ))}
                              </div>
                            )}

                            {/* ✅ Yes/No Buttons */}
                            <div className="btn-group d-flex gap-3 mt-3" role="group">
                              <button className="btn btn-yes" onClick={() => handleYesNoClick(question, "yes")}>
                                Yes ₹ {selectedOption ? selectedOption.yes_point : question.yes_point}
                              </button>
                              <button className="btn btn-no" onClick={() => handleYesNoClick(question, "no")}>
                                No ₹ {selectedOption ? selectedOption.no_point : question.no_point}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p>No questions found for this category.</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* ✅ OrderWidget for placing orders with the selected option pre-filled */}
      {selectedQuestion && accessToken && (
        <OrderWidget
          event={selectedQuestion}
          selectedOption={selectedQuestion.selectedOption}
          selectedYesNo={selectedQuestion.selectedYesNo} // ✅ Pass the selected Yes/No option
          onClose={() => setSelectedQuestion(null)}
        />
      )}
    </section>
  );
};

export default CategoryTabs;
