import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuth } from "../context/AuthContext"; // ✅ Import useAuth
import "./style/EventsSection.css";
import OrderWidget from "./OrderWidget"; // ✅ Importing OrderWidget for sliding panel

const EventsSection = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { accessToken } = useAuth(); // ✅ Get access token to check login
  const navigate = useNavigate();
  const limit = 10;

  const fetchEvents = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/v1/getAllQuestions?limit=${limit}&offset=${offset}`,
        {
          method: "GET",
          headers: {
            headers: { Authorization: `Bearer ${accessToken}` },
            withCredentials: true,
          }
        }
      );

      const data = await response.json();

      if (data.status === 1) {
        setEvents((prev) => [...prev, ...data.questions]);

        // Set default selected options for polling questions
        const defaultOptions = {};
        data.questions.forEach((event) => {
          if (event.polling === 2 && event.options && event.options.length > 0) {
            defaultOptions[event.id] = event.options[0].option_id;
          }
        });
        setSelectedOptions((prev) => ({ ...prev, ...defaultOptions }));

        if (data.questions.length < limit) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []); // Runs only once on mount

  const handleOptionClick = (questionId, optionId) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [questionId]: optionId,
    }));
  };

  const handleYesNoClick = (event, selectedYesNo) => {
    // 🔴 If user is NOT logged in, redirect to SingleTrade page with event ID
    if (!accessToken) {
      navigate(`/singletrade/${event.id}`);
      return;
    }

    // ✅ If user is logged in, open OrderWidget
    const selectedOption = event.polling === 2
      ? event.options?.find(option => option.option_id === selectedOptions[event.id])
      : null;

    setSelectedEvent({ 
      ...event, 
      selectedOption, 
      selectedYesNo // ✅ Pass the selected Yes/No option explicitly
    });
  };

  return (
    <section className="content-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="section-title">All Events</h3>
            <hr />
            {loading ? (
              <p style={{ textAlign: "center" }}>
                <span className="loader"></span>
              </p>
            ) : events.length > 0 ? (
              <InfiniteScroll
                dataLength={events.length}
                next={() => {
                  if (hasMore) {
                    setOffset((prevOffset) => prevOffset + limit);
                    fetchEvents();
                  }
                }}
                hasMore={hasMore}
                loader={<p style={{ textAlign: "center" }}><span className="loader"></span></p>}
                endMessage={!hasMore && <p style={{ textAlign: "center" }}>No more events to load.</p>}
              >
                <div className="row">
                  {events.map((event, index) => {
                    const selectedOption = event.polling === 2 
                      ? event.options?.find(option => option.option_id === selectedOptions[event.id])
                      : null;

                    return (
                      <div className="col-md-4 mb-4 fade-in" key={`${event.id}-${index}`}>
                        <div className="card events-card">
                          <div className="card-header gap-2">
                            <i className="fa fa-users"></i>
                            <span>{event.traders_count} traders</span>
                          </div>
                          <div className="card-body">
                            <div className="d-flex gap-3">
                              <div className="match-img">
                                <img
                                  src="assets/images/card-thumb.png"
                                  className="img-fluid"
                                  alt="Match Thumbnail"
                                />
                              </div>
                              <div className="card-details">
                                <p className="match-info">
                                  <Link to={`/singletrade/${event.id}`} className="question_link">{event.question}</Link>
                                  </p>
                                <p style={{ fontSize: "12px", color: "#B7B8B6" }}>
                                  {event.description}
                                </p>
                              </div>
                            </div>
                            <p className="head-to-head">{event.head_to_head}</p>

                            {/* Polling Options */}
                            {event.polling === 2 && event.options && event.options.length > 0 && (
                              <div className="polling-options d-flex gap-2 mt-3">
                                {event.options.map((option) => (
                                  <span
                                    key={`${event.id}-${option.option_id}`}
                                    onClick={() => handleOptionClick(event.id, option.option_id)}
                                    className={`polling-option ${
                                      selectedOptions[event.id] === option.option_id ? "active" : ""
                                    }`}
                                    style={{
                                      cursor: "pointer",
                                      padding: "5px 10px",
                                      border: "1px solid rgba(227, 166, 22, 1)",
                                      borderRadius: "5px",
                                      fontSize: "12.5px",
                                      backgroundColor:
                                        selectedOptions[event.id] === option.option_id
                                          ? "rgba(227, 166, 22, 1)"
                                          : "transparent",
                                      color: "#fff",
                                    }}
                                  >
                                    {option.options_str}
                                  </span>
                                ))}
                              </div>
                            )}

                          <div className="btn-group d-flex gap-3 mt-3" role="group">
                            <button className="btn btn-yes" onClick={() => handleYesNoClick(event, "yes")}>
                              Yes ₹{" "}
                              {event.polling === 2 && selectedOption
                                ? selectedOption.yes_point
                                : event.yes_point}
                            </button>
                            <button className="btn btn-no" onClick={() => handleYesNoClick(event, "no")}>
                              No ₹{" "}
                              {event.polling === 2 && selectedOption
                                ? selectedOption.no_point
                                : event.no_point}
                            </button>
                          </div>

                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </InfiniteScroll>
            ) : (
              <p style={{ fontWeight: "bold" }}>Events not found.</p>
            )}
          </div>
        </div>
      </div>

      {selectedEvent && (
        <OrderWidget
          event={selectedEvent}
          selectedYesNo={selectedEvent.selectedYesNo} 
          selectedOption={selectedEvent.selectedOption} 
          onClose={() => setSelectedEvent(null)}
        />
      )}

    </section>
  );
};

export default EventsSection;
