import React from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';
import AuthLayout from '../layouts/AuthLayout';
import Login from '../pages/Login';
import OTPVerification from '../pages/OTPVerification';
import { useAuth } from '../context/AuthContext';

const AuthRoutes = () => {
  const { accessToken } = useAuth();
  const location = useLocation();

  // ✅ Get last visited page from sessionStorage (fallback to home)
  const lastVisitedPage = sessionStorage.getItem("lastVisitedPage") || "/";

  return [
    <Route
      key="/login"
      path="/login"
      element={
        accessToken ? (
          <Navigate to={lastVisitedPage} replace />
        ) : (
          <AuthLayout>
            <Login />
          </AuthLayout>
        )
      }
    />,
    <Route
      key="/otpverification"
      path="/otpverification"
      element={
        accessToken ? (
          <Navigate to={lastVisitedPage} replace />
        ) : (
          <AuthLayout>
            <OTPVerification />
          </AuthLayout>
        )
      }
    />,
  ];
};

export default AuthRoutes;
