import React from 'react';

const CategoryMenu = () => {
  return (
    <section className="category-menu">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="fantasy-games py-3">
              <ul>
                {/* <li><a href="/" className="active">All events</a></li> */}
                <li><a href="/" className="active">Cricket</a></li>
                {/* <li><a href="/">Crypto</a></li>
                <li><a href="/">Kabaddi</a></li>
                <li><a href="/">News</a></li>
                <li><a href="/">Economy</a></li>
                <li><a href="/">Election</a></li>
                <li><a href="/">Youtube</a></li>
                <li><a href="/">Football</a></li>
                <li><a href="/">Stocks</a></li>
                <li><a href="/">Basketball</a></li>
                <li><a href="/">Motorsports</a></li>
                <li><a href="/">Tennis</a></li>
                <li><a href="/">Probo</a></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategoryMenu;
