import React from 'react';
import { Helmet } from 'react-helmet';
import PageBreadcrumb from '../components/PageBreadcrumb';
import TradeDetail from '../components/TradeDetail';
const SingleTrade = () => {
  return (
    <div>
      <Helmet>
        <title>Prediction API - Single Trade</title>
        <meta name="description" content="View the details of a single trade on Prediction API." />
      </Helmet>
      <PageBreadcrumb />
      <TradeDetail />
    </div>
  );
};

export default SingleTrade;
