import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageBreadcrumb from '../components/PageBreadcrumb';
import EditProfileForm from '../components/Forms/EditProfileForm';

const EditProfile = () => {
  return (
    <div>
      <Helmet>
        <title>Prediction API - Single Trade</title>
        <meta name="description" content="View the details of a single trade on Prediction API." />
      </Helmet>
      <PageBreadcrumb pageName="Edit Profile" />
     <EditProfileForm />
    </div>
  );
};

export default EditProfile;
