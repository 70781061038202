import React from 'react';

const TransactionTabs = ({ tabs, activeTab, onTabChange }) => (
  <ul className="nav nav-tabs border-0">
    {tabs.map((tab) => (
      <li className="nav-item" key={tab}>
        <button
          className={`nav-link tab-link ${activeTab === tab ? 'active' : ''}`}
          onClick={() => onTabChange(tab)}
        >
          {tab}
        </button>
      </li>
    ))}
  </ul>
);

export default TransactionTabs;
