import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from 'react-router-dom'; 
import { useAuth } from "../context/AuthContext";
const MyWallet = () => {
  const { accessToken, userBalance, logout } = useAuth();
  const [walletData, setWalletData] = useState({
    deposit: 0,
    winnings: 0,
    referral: 0,
  });


  useEffect(() => {
    const fetchWalletData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/transactions/wallet-summary`, {
          headers: { Authorization: `Bearer ${accessToken}` },
          withCredentials: true,
        });

        setWalletData(response.data);
      } catch (error) {
        console.error("❌ Error fetching wallet summary:", error);
      }
    };

    fetchWalletData();
  }, [accessToken]);


  return (
    <div className="container my-5">
      {/* Total Balance */}
      <div className="text-center mb-4">
        <h5>Total Balance</h5>
        <h1 className="fw-bold">₹{userBalance.toFixed(2)}</h1>
      </div>

      {/* Balance Cards */}
      <div className="row text-center mb-4">
        <div className="col-md-4">
          <div className="card p-4 shadow-sm h-100 text-center">
            <i className="fas fa-wallet display-6 mb-3"></i>
            <h6 className="text-secondary">
              Deposit{' '}
              <i
                className="fas fa-info-circle"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Money deposited by you that you can use to join any contest but can't withdraw"
              ></i>
            </h6>
            <h2 className="fw-bold">₹{walletData.deposit.toFixed(2)}</h2>
                <Link to="/recharge" className="btn btn-dark btn-lg w-100 mt-2">Recharge</Link>
           
           
            {/* <ul className="navbar-nav">
              <li className="dropdown">
                <a
                  className="d-flex justify-content-between align-items-center dropdown-toggle brakdown-link"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  View breakdown
                </a>
                <ul className="dropdown-menu w-100">
                  <li className="p-3">
                    <h6 className="text-secondary">
                      Deposit{' '}
                      <i
                        className="fas fa-info-circle"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Money deposited by you that you can use to join any contest but can't withdraw"
                      ></i>
                    </h6>
                    <h4 className="fw-bold">₹156.07</h4>
                  </li>
                  <li>
                    <hr className="m-0" />
                  </li>
                  <li className="p-3">
                    <h6 className="text-secondary">
                      Deposit{' '}
                      <i
                        className="fas fa-info-circle"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Money deposited by you that you can use to join any contest but can't withdraw"
                      ></i>
                    </h6>
                    <h4 className="fw-bold">₹156.07</h4>
                  </li>
                </ul>
              </li>
            </ul> */}


          </div>
        </div>
        <div className="col-md-4">
          <div className="card p-4 shadow-sm h-100 text-center">
            <i className="fas fa-trophy display-6 mb-3"></i>
            <h6 className="text-secondary">
              Winnings{' '}
              <i
                className="fas fa-info-circle"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Funds that you can withdraw or reuse to join events"
              ></i>
            </h6>
            <h2 className="fw-bold">₹{walletData.winnings.toFixed(2)}</h2>
            <button className="btn btn-secondary btn-lg w-100 mt-2" disabled>
              Withdraw
            </button>
            <small className="text-danger d-block mt-2">
              Complete KYC to withdraw funds
            </small>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card p-4 shadow-sm h-100 text-center">
            <i className="fas fa-users display-6 mb-3"></i>
            <h6 className="text-secondary">
              Referral Affiliation{' '}
              <i
                className="fas fa-info-circle"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Funds that you can withdraw or reuse to join events by transferring to the deposit wallet"
              ></i>
            </h6>
            <h2 className="fw-bold">₹{walletData.referral.toFixed(2)}</h2>
            <button className="btn btn-outline-primary btn-lg w-100 mt-2">
              Invite and Earn
            </button>
          </div>
        </div>
      </div>

      {/* Quick Actions */}
      <h5 className="mb-3">Quick Actions</h5>
      <div className="row g-3">
        <div className="col-md-6 col-lg-4">
          <div className="card p-4 shadow-sm h-100 bg-dark text-white text-center">
            <i className="fa fa-history display-6 mb-3"></i>
            <h6 className="h5">Transaction History</h6>
            <p className="small">For all balance debits & credits</p>
            <Link className="btn btn-outline-primary btn-lg mt-2" to="/transactionhistory">
              ➔
            </Link>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="card p-4 shadow-sm h-100 bg-dark text-white text-center">
            <i className="fa fa-user-check display-6 mb-3"></i>
            <h6 className="h5">KYC Verification</h6>
            <p className="small">Tap to verify</p>
            <button className="btn btn-outline-primary btn-lg mt-2">➔</button>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="card p-4 shadow-sm h-100 bg-dark text-white text-center">
            <i className="fas fa-file-alt display-6 mb-3"></i>
            <h6 className="h5">Statements and Tax Details</h6>
            <p className="small">For ledger and TDS certificates</p>
            <button className="btn btn-outline-primary btn-lg mt-2">➔</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyWallet;
