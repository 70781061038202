import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import PageBreadcrumb from '../components/PageBreadcrumb';
import TradeDetail from '../components/TradeDetail';
import TradeSidebar from '../components/TradeSidebar';
import { useAuth } from "../context/AuthContext";
import axios from 'axios';

const SingleTrade = () => {
  const { id } = useParams(); // Retrieve question ID from route parameters
  const { accessToken } = useAuth();
  const [questionDetails, setQuestionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeOption, setActiveOption] = useState(null); // Track active option
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    const fetchQuestionDetails = async () => {
      try {
        const response = await axios.get(`${serverUrl}/v1/getQuestionById/${id}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
          withCredentials: true,
        });

        if (response.data.status === 1) {
          const question = response.data.question;
          setQuestionDetails(question);

          // ✅ Set first option as active by default
          if (question.options && question.options.length > 0) {
            setActiveOption(question.options[0]);
          }
        } else {
          console.error('Failed to fetch question details:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching question details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestionDetails();
  }, [id, serverUrl, accessToken]);

  return (
    <div>
      <Helmet>
        <title>Prediction API - Single Trade</title>
        <meta name="description" content="View the details of a single trade on Prediction API." />
      </Helmet>
      <PageBreadcrumb pageName="Event Details" />

      <section className="content-section">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              {/* ✅ Pass fetched data to TradeDetail */}
              <TradeDetail questionDetails={questionDetails} loading={loading} activeOption={activeOption} setActiveOption={setActiveOption} />
            </div>
            {/* ✅ Show sidebar **only if** user is logged in */}
            {accessToken && (
              <div className="col-md-3">
                <TradeSidebar event={questionDetails} selectedOption={activeOption} />
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default SingleTrade;
