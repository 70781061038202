import React from "react";

const formatDate = (isoDate) => {
  if (!isoDate) return "N/A";
  
  return new Date(isoDate).toLocaleString("en-IN", {
    weekday: "long", // Monday
    day: "2-digit",  // 16
    month: "long",   // December
    year: "numeric", // 2024
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,    // 12-hour format
  });
};

const EventSummary = ({ eventData }) => {
  if (!eventData) return null;

  return (
    <div className="card shadow-sm p-3 mb-4 text-center">
      <h4 className="fw-bold">{eventData.question}</h4>
      <p className="text-muted">{eventData.description}</p>

      <div className="row text-center mt-4">
        <div className="col-md-4">
          <h5 className="fw-bold">Start Date</h5>
          <p>{formatDate(eventData.start_on)}</p>
        </div>
        <div className="col-md-4">
          <h5 className="fw-bold">End Date</h5>
          <p>{formatDate(eventData.expires_in)}</p>
        </div>
        <div className="col-md-4">
          <h5 className="fw-bold">Status</h5>
          <p>{eventData.result || "Pending"}</p>
        </div>
      </div>
    </div>
  );
};

export default EventSummary;
