import React, { useState } from "react";
import PortfolioSummary from "../components/PortfolioSummary";
import TradeList from "../components/TradeList";

const PortfolioPage = () => {
  const [activeTab, setActiveTab] = useState("active");

  return (
    <div className="container mt-4">
      {/* Bootstrap Nav Tabs */}
        <div className="trade_tabs py-3">
        <ul className="nav nav-tabs">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "active" ? "active" : ""}`}
            onClick={() => setActiveTab("active")}
          >
            Active Trades
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "closed" ? "active" : ""}`}
            onClick={() => setActiveTab("closed")}
          >
            Closed Trades
          </button>
        </li>
      </ul>
        </div>

      {/* Portfolio Summary */}
      <PortfolioSummary />

      {/* Trade List Component */}
      <TradeList tradeType={activeTab} />
    </div>
  );
};

export default PortfolioPage;
