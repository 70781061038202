import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const Header = () => {
  const { accessToken, userBalance, profileImage, socket, logout } = useAuth();
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [imageError, setImageError] = useState(false);
  
  const imgRef = useRef(null); // ✅ Prevents re-triggering `onError`

  // ✅ Correctly format the profile image URL
  // const profilePicUrl = imageError 
  //   ? `${process.env.REACT_APP_SERVER_IMG_URL}/uploads/profile_pics/default.png` // ✅ Use default on error
  //   : profileImage.startsWith("http") 
  //     ? profileImage 
  //     : `${process.env.REACT_APP_SERVER_IMG_URL}${profileImage}`;


  // const profilePicUrl = accessToken && profileImage
  // ? (profileImage.startsWith("http") ? profileImage : `${process.env.REACT_APP_SERVER_IMG_URL}${profileImage}`)
  // : `${process.env.REACT_APP_SERVER_IMG_URL}/uploads/profile_pics/default.png`; // ✅ Default image when not logged in


  // const profilePicUrl = accessToken 
  // ? (profileImage && typeof profileImage === "string" && profileImage.startsWith("http") 
  //     ? profileImage 
  //     : `${process.env.REACT_APP_SERVER_IMG_URL}${profileImage || "/uploads/profile_pics/default.png"}` // ✅ Ensures default fallback
  //   )
  // : `${process.env.REACT_APP_SERVER_IMG_URL}/uploads/profile_pics/default.png`; // ✅ When not logged in





  // const profilePicUrl = accessToken 
  // ? profileImage && typeof profileImage === "string"
  //   ? profileImage.startsWith("https")
  //     ? profileImage
  //     : `${process.env.REACT_APP_SERVER_IMG_URL}${profileImage}`
  //   : `${process.env.REACT_APP_SERVER_IMG_URL}/uploads/profile_pics/default.png` // ✅ Ensure a valid default
  // : null; // ✅ No image when user is not logged in




  // const profilePicUrl = accessToken 
  // ? profileImage && typeof profileImage === "string"
  //   ? profileImage.startsWith("https")
  //     ? profileImage === "default.png"
  //       ? `${process.env.REACT_APP_SERVER_IMG_URL}/uploads/profile_pics/default.png`
  //       : profileImage
  //     : `${process.env.REACT_APP_SERVER_IMG_URL}/${profileImage === "default.png" ? "uploads/profile_pics/default.png" : profileImage}`
  //   : `${process.env.REACT_APP_SERVER_IMG_URL}/uploads/profile_pics/default.png` // ✅ Ensure a valid default
  // : null; // ✅ No image when user is not logged in



  // const profilePicUrl = accessToken 
  // ? profileImage && typeof profileImage === "string"
  //   ? profileImage.startsWith("https")
  //     ? profileImage === "default.png"
  //       ? `${process.env.REACT_APP_SERVER_IMG_URL}/uploads/profile_pics/default.png`
  //       : profileImage
  //     : `${process.env.REACT_APP_SERVER_IMG_URL}${profileImage.startsWith("/") ? profileImage : `/${profileImage}`}`
  //   : `${process.env.REACT_APP_SERVER_IMG_URL}/uploads/profile_pics/default.png`
  // : null;



  const serverImgUrl = process.env.REACT_APP_SERVER_IMG_URL || ""; // Ensure it's always defined

const profilePicUrl = accessToken && profileImage 
  ? profileImage.startsWith("https") 
    ? profileImage 
    : profileImage === "default.png"
      ? `${serverImgUrl}/uploads/profile_pics/default.png`
      : `${serverImgUrl}${profileImage.startsWith("/") ? profileImage : `/${profileImage}`}`
  : `${serverImgUrl}/uploads/profile_pics/default.png`; // Default image when not logged in



  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
    setProfileOpen(false);
  };

  const toggleProfile = () => {
    setProfileOpen(!profileOpen);
    setNotificationsOpen(false);
  };

  useEffect(() => {
    if (!socket) return;

    socket.on("receiveWinNotification", (data) => {
      setNotifications((prev) => [{ message: data.message, id: Date.now() }, ...prev]);
    });

    return () => {
      socket.off("receiveWinNotification");
    };
  }, [socket]);

  return (
    <header>
      <nav className="navbar fixed-top bg-header navbar-expand-lg">
        <div className="container">
          <NavLink className="navbar-brand" to="/">Prediction API</NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" to="/" end>Home</NavLink>
              </li>
              {accessToken && (
                <li className="nav-item">
                  <NavLink className="nav-link" to="/portfolio">Portfolio</NavLink>
                </li>
              )}
              <li className="nav-item">
                <a className="nav-link" href="#">Read</a>
              </li>
            </ul>

            {accessToken ? (
              <div className="d-flex align-items-center gap-3">
                {/* ✅ Wallet Balance */}
                <NavLink to="/wallet" className="nav-link fw-bold text-white d-flex align-items-center gap-1">
                  <i className="fas fa-wallet"></i> ₹{userBalance.toFixed(2)}
                </NavLink>

                {/* ✅ Notifications Dropdown */}
                <div className="dropdown">
                  <button className="nav-link btn position-relative" onClick={toggleNotifications}>
                    <i className="fas fa-bell" style={{ color: '#fff', paddingLeft: '10px', paddingRight: '10px' }}></i>
                    {notifications.length > 0 && (
                      <span className="badge bg-danger position-absolute top-0 start-100 translate-middle">
                        {notifications.length}
                      </span>
                    )}
                  </button>
                  {notificationsOpen && (
                    <ul className="dropdown-menu show dropdown-menu-end">
                      {notifications.length === 0 ? (
                        <li><span className="dropdown-item text-muted">No Notifications</span></li>
                      ) : (
                        notifications.map((notification, index) => (
                          <li key={index} className="dropdown-item">{notification.message}</li>
                        ))
                      )}
                    </ul>
                  )}
                </div>

                {/* ✅ Profile Dropdown */}
                  {accessToken && (
                    <div className="profile-dropdown">
                      <button className="nav-link profile-btn" onClick={toggleProfile}>
                        <img 
                          ref={imgRef}
                          src={profilePicUrl} 
                          className="rounded-circle" 
                          width="40px" 
                          height="40px" 
                          alt="Profile"
                          onError={() => {
                            if (!imageError) {
                              setImageError(true);
                            }
                          }}
                        />
                      </button>
                      {profileOpen && (
                        <ul className="dropdown-menu show">
                          <li><NavLink className="dropdown-item" to="/profile"><i className="fa fa-user-alt"></i> Profile</NavLink></li>
                          <li><NavLink className="dropdown-item" to="/wallet"><i className="fa fa-wallet"></i> Wallet</NavLink></li>
                          <li><button className="dropdown-item" onClick={logout}><i className="fa fa-sign-out-alt"></i> Logout</button></li>
                        </ul>
                      )}
                    </div>
                  )}
              </div>
            ) : (
              <div className="d-flex gap-3">
                <button className="btn theme-btn-outline">Download App</button>
                <NavLink to="/login" className="btn theme-btn">Login/Signup</NavLink>
              </div>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
