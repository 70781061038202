import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';

const EditProfileForm = () => {
  const { accessToken, profileImage, setProfileImage, fetchUserDetails } = useAuth();
  const [refreshKey, setRefreshKey] = useState(0); // ✅ Forces re-render
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');

  // ✅ Fetch user profile and populate form
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/get-profile`, {
          headers: { Authorization: `Bearer ${accessToken}` },
          withCredentials: true,
        });

        if (response.status === 200) {
          const { name, email, mobile } = response.data;
          setValue('name', name || '');
          setValue('email', email || '');
          setValue('mobile', mobile || '');
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, [accessToken, setValue]);

  // ✅ Handle File Selection
  const handleImageChange = (e) => {
    setError('');
    if (e.target.files.length > 0) {
      const file = e.target.files[0];

      // ✅ Validate file type & size
      const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];
      if (!allowedTypes.includes(file.type)) {
        setError("Only PNG, JPG, and JPEG files are allowed.");
        return;
      }
      if (file.size > 2 * 1024 * 1024) {
        setError("File size must be 2MB or smaller.");
        return;
      }

      setImage(file);
    }
  };

  // ✅ Upload Profile Picture
  // const uploadProfilePic = async () => {
  //   if (!image) {
  //     setError("Please select an image to upload.");
  //     return;
  //   }

  //   setUploading(true);
  //   const formData = new FormData();
  //   formData.append("profile_pic", image);

  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/upload-profile-pic`, formData, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //         'Content-Type': 'multipart/form-data',
  //       },
  //       withCredentials: true,
  //     });

  //     if (response.status === 200) {
  //       setProfileImage(response.data.profile_pic); // ✅ Update profile image globally in context
  //       setMessage("Profile picture updated successfully!");
  //     } else {
  //       setError(response.data.message || "Failed to upload profile picture.");
  //     }
  //   } catch (error) {
  //     console.error("Error uploading profile picture:", error);
  //     setError("Failed to upload profile picture.");
  //   } finally {
  //     setUploading(false);
  //   }
  // };



  // ✅ Upload Profile Picture
// ✅ Upload Profile Picture
// const uploadProfilePic = async () => {
//   if (!image) {
//     setError("Please select an image to upload.");
//     return;
//   }

//   setUploading(true);
//   const formData = new FormData();
//   formData.append("profile_pic", image);

//   try {
//     const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/upload-profile-pic`, formData, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//         'Content-Type': 'multipart/form-data',
//       },
//       withCredentials: true,
//     });

//     // ✅ Ensure the response is correctly checked
//     if (response && response.data && response.data.profile_pic) {
//       // ✅ Update profile image in AuthContext
//       // setProfileImage(`${process.env.REACT_APP_SERVER_IMG_URL}${response.data.profile_pic}`);
//       fetchUserDetails(accessToken);
//       // ✅ Display success message
//       setMessage("Profile picture updated successfully!");
//       setError('');
//     } else {
//       setError(response.data.message || "Failed to upload profile picture.");
//     }
//   } catch (error) {
//     console.error("Error uploading profile picture:", error);
//     setError(error.response?.data?.message || "Failed to upload profile picture.");
//   } finally {
//     setUploading(false);
//   }
// };



const uploadProfilePic = async () => {
  if (!image) {
    setError("Please select an image to upload.");
    return;
  }

  setUploading(true);
  const formData = new FormData();
  formData.append("profile_pic", image);

  try {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/upload-profile-pic`, formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
    });

    if (response && response.data && response.data.profile_pic) {
      const newProfileImage = `${process.env.REACT_APP_SERVER_IMG_URL}${response.data.profile_pic}`;

      // ✅ Force Context Update & Component Re-render
      setProfileImage(newProfileImage);
      await fetchUserDetails(accessToken);
      setRefreshKey(prevKey => prevKey + 1); // ✅ Ensures immediate UI refresh

      setMessage("Profile picture updated successfully!");
      setError('');

      // ✅ Auto-close modal
      const modalElement = document.getElementById("uploadModal");
      const modalInstance = bootstrap.Modal.getInstance(modalElement);
      if (modalInstance) modalInstance.hide();
      
    } else {
      setError(response.data.message || "Failed to upload profile picture.");
    }
  } catch (error) {
    console.error("Error uploading profile picture:", error);
    setError(error.response?.data?.message || "Failed to upload profile picture.");
  } finally {
    setUploading(false);
  }
};


  // ✅ Submit Updated Profile Details
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/update-profile`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      if (response.status === 200) {
        setMessage("Profile updated successfully!");
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      setMessage("Failed to update profile.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <form onSubmit={handleSubmit(onSubmit)} className="bg-dark rounded text-white p-5">
            <div className="text-center mb-5">
              <div className="position-relative mb-2">
                {/* ✅ Use profileImage from AuthContext */}
                <img 
                  key={refreshKey} // ✅ Forces re-render
                  src={profileImage} 
                  className="rounded-circle mb-2" 
                  width="100px" 
                  height="100px" 
                  alt="Profile"
                  onError={(e) => e.target.src = `${process.env.REACT_APP_SERVER_IMG_URL}/uploads/profile_pics/default.png`} 
                />

                <button type="button" className="btn btn-sm btn-light position-absolute profile-edit-icon" data-bs-toggle="modal" data-bs-target="#uploadModal">
                  <i className="fa fa-pen-alt"></i>
                </button>
              </div>
              <p>Profile photo will be visible on your leaderboard</p>
            </div>

            {/* Name */}
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name</label>
              <input type="text" id="name" className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                {...register('name', { required: 'Name is required' })} />
              {errors.name && <div className="invalid-feedback">{errors.name.message}</div>}
            </div>

            {/* Email */}
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" id="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                {...register('email', { required: 'Email is required' })} />
              {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
            </div>

            {/* Mobile Number */}
            <div className="mb-4">
              <label htmlFor="mobile" className="form-label">Mobile Number</label>
              <input type="tel" id="mobile" className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                {...register('mobile', { required: 'Mobile number is required' })} />
              {errors.mobile && <div className="invalid-feedback">{errors.mobile.message}</div>}
            </div>

            <button type="submit" className="btn btn-lg w-100 btn-primary" disabled={loading}>
              {loading ? "Updating..." : "Submit"}
            </button>

            {message && <p className="mt-3 text-center">{message}</p>}
          </form>
        </div>
      </div>

      {/* ✅ Modal for Profile Picture Upload */}
      <div className="modal fade" id="uploadModal" tabIndex="-1" aria-labelledby="uploadModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="uploadModalLabel">Upload Profile Picture</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p className="text-muted">Allowed formats: PNG, JPG, JPEG | Max size: 2MB</p>
              <input type="file" className="form-control" accept="image/png, image/jpg, image/jpeg" onChange={handleImageChange} />
              {error && <p className="text-danger mt-2">{error}</p>}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={uploadProfilePic} disabled={uploading}>
                {uploading ? "Uploading..." : "Upload"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfileForm;
