import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const ProtectedRoute = () => {
  const { accessToken, loading } = useAuth();
  const location = useLocation();

  if (loading) return null; // Prevent redirect before token check

  // ✅ Only update last visited page if it's NOT login/OTP pages
  useEffect(() => {
    const isAuthPage = ["/login", "/otpverification"].includes(location.pathname);
    if (accessToken && !isAuthPage) {
      sessionStorage.setItem("lastVisitedPage", location.pathname);
    }
  }, [location.pathname, accessToken]);

  if (!accessToken) {
    // ✅ Store last visited page before redirecting to login
    if (!["/login", "/otpverification"].includes(location.pathname)) {
      sessionStorage.setItem("lastVisitedPage", location.pathname);
    }
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
