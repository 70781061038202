import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { Link } from "react-router-dom";
const TradeList = ({ tradeType }) => {
  const { accessToken } = useAuth(); // ✅ Get token from AuthContext
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [trades, setTrades] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchTrades();
  }, [tradeType]);

  const fetchTrades = async () => {
    if (!accessToken) {
        setMessage("Authorization token is missing.");
        return;
    }

    setLoading(true);
    setMessage("");

    try {
        const response = await axios.get(
            `${serverUrl}/bids/userbids?status=${tradeType === "active" ? "accepted" : "completed,canceled"}`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
                withCredentials: true,
            }
        );

        console.log("✅ API Response:", response);

        if (response.status === 200) {
            setTrades(response.data.bids);
        } else {
            setMessage(response.data.message || "Failed to fetch trades.");
        }
    } catch (error) {
        console.error("❌ API Error:", error);
        setMessage(error.response?.data?.message || "Failed to fetch trades.");
    } finally {
        setLoading(false);
    }
};


  return (
    <div className="trade-list mt-4">
      {message && <p className="text-center text-danger">{message}</p>}
      {loading ? (
        <p className="text-center">Loading trades...</p>
      ) : trades.length === 0 ? (
        <p className="text-center">No trades found.</p>
      ) : (
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Event</th>
                <th>Investment</th>
                <th>Returns</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
                {trades.map((trade) => (
                    <tr key={trade.id}>
                    <td>{trade.question}</td>
                    <td>₹{trade.price ? Number(trade.price).toFixed(2) : "0.00"}</td>
                    <td className={trade.expected_payout >= 0 ? "text-success" : "text-danger"}>
                        ₹{trade.expected_payout ? Number(trade.expected_payout).toFixed(2) : "0.00"}
                    </td>
                    <td>
                    <Link to={`/portfolio/event/${trade.question_id}`} className="btn btn-sm btn-outline-secondary">
                          View &gt;
                     </Link>
                    </td>
                    </tr>
                ))}
                </tbody>

          </table>
        </div>
      )}
    </div>
  );
};

export default TradeList;
