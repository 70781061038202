import React from 'react';
import { Helmet } from 'react-helmet-async';
import LoginForm from '../components/Forms/LoginForm';
const Login = () => {
  return (
    <div>
      <Helmet>
        <title>Prediction API - Login</title>
        <meta name="description" content="Explore various categories of events in the Prediction API." />
      </Helmet>
        <LoginForm />
    </div>
  );
};

export default Login;
