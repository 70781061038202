import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { io } from 'socket.io-client';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [userBalance, setUserBalance] = useState(0.00);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [socket, setSocket] = useState(null);
  // const [profileImage, setProfileImage] = useState(null); // ✅ Added profile image state

  const [profileImage, setProfileImage] = useState(`${process.env.REACT_APP_SERVER_IMG_URL}/uploads/profile_pics/default.png`);

  const navigate = useNavigate();
  const location = useLocation();

  const fetchUserBalance = async (token) => {
    if (!token) return;
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/balance`, {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true
      });
      setUserBalance(response.data.balance);
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  const fetchUserDetails = async (token) => {
    if (!token) return null;
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/get-profile`, {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true
      });
  
      setUserId(response.data.id);
  
      // ✅ Force React to update profileImage state
      const newProfileImage = response.data.image 
        ? `${process.env.REACT_APP_SERVER_IMG_URL}${response.data.image}`
        : `${process.env.REACT_APP_SERVER_IMG_URL}/uploads/profile_pics/default.png`;
  
      setProfileImage(prevImage => prevImage !== newProfileImage ? newProfileImage : `${newProfileImage}?timestamp=${new Date().getTime()}`);
  
      return response.data.id;
    } catch (error) {
      console.error('Error fetching user details:', error);
      return null;
    }
  };
  
  
  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/refresh-token`, {}, {
          withCredentials: true,
        });

        if (response.data.accessToken) {
          setAccessToken(response.data.accessToken);
          await fetchUserBalance(response.data.accessToken);
          const fetchedUserId = await fetchUserDetails(response.data.accessToken);

          if (fetchedUserId) {
            console.log(`📡 [DEBUG] Registering User ID: ${fetchedUserId}`);

            const socketURL = process.env.REACT_APP_SOCKET_SERVER_URL || "http://localhost:3007";
            const newSocket = io(socketURL, { transports: ["websocket"], withCredentials: true });

            newSocket.on("connect", () => {
              console.log("🔌 [SOCKET] Connected to User Backend");
              newSocket.emit("registerUser", fetchedUserId);
            });

            newSocket.on("disconnect", () => {
              console.log("❌ [SOCKET] Disconnected from User Backend");
            });

            newSocket.on("connect_error", (error) => {
              console.error("⚠️ [SOCKET ERROR] Connection Error:", error.message);
            });

            setSocket(newSocket);
          }
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
        setAccessToken(null);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    };

    fetchAccessToken();
  }, []);

  const login = async (token) => {
    setAccessToken(token);
    await fetchUserBalance(token);
    const fetchedUserId = await fetchUserDetails(token);

    if (fetchedUserId) {
      console.log(`📡 [DEBUG] Registering User ID: ${fetchedUserId}`);

      const socketURL = process.env.REACT_APP_SOCKET_SERVER_URL || "http://localhost:3007";
      const newSocket = io(socketURL, { transports: ["websocket"], withCredentials: true });

      newSocket.on("connect", () => {
        console.log("🔌 [SOCKET] Connected to User Backend");
        newSocket.emit("registerUser", fetchedUserId);
      });

      newSocket.on("disconnect", () => {
        console.log("❌ [SOCKET] Disconnected from User Backend");
      });

      newSocket.on("connect_error", (error) => {
        console.error("⚠️ [SOCKET ERROR] Connection Error:", error.message);
      });

      setSocket(newSocket);
    }

    const lastVisitedPage = sessionStorage.getItem("lastVisitedPage") || "/";
    sessionStorage.removeItem("lastVisitedPage");
    navigate(lastVisitedPage, { replace: true });
  };

  const logout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/logout`, {}, { withCredentials: true });
      setAccessToken(null);
      setUserBalance(0.00);
      setUserId(null);
      setProfileImage(`${process.env.REACT_APP_SERVER_URL}/uploads/profile_pics/default.png`); // ✅ Reset profile image on logout
      sessionStorage.removeItem("lastVisitedPage");
      navigate('/', { replace: true });

      if (socket) {
        socket.disconnect();
        setSocket(null);
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  if (loading) {
    return (
      <div className="loader-container">
        <span className="loader"></span>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ accessToken, userBalance, userId, profileImage, setProfileImage, fetchUserDetails, login, logout, loading, fetchUserBalance, socket }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
