import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../context/AuthContext";

const MyProfile = () => {
  // ✅ Correctly use `useAuth()` inside the component
  const { accessToken, userBalance, profileImage, socket, logout } = useAuth();
  
  const [imageError, setImageError] = useState(false);
  const imgRef = useRef(null); // ✅ Prevents re-triggering `onError`
  
  // ✅ Correctly format the profile image URL
  const profilePicUrl = imageError
    ? `${process.env.REACT_APP_SERVER_IMG_URL}/uploads/profile_pics/default.png` // ✅ Use default on error
    : profileImage.startsWith("https")
      ? profileImage
      : `${process.env.REACT_APP_SERVER_IMG_URL}${profileImage}`;

  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState({
    name: "",
    eventsPlayed: 0,
    eventsWon: 0,
    totalWinnings: 0,
  });

  const fetchProfileData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/get-profile-stats`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setProfileData({
          name: response.data.name || "User",
          eventsPlayed: response.data.events_played || 0,
          eventsWon: response.data.events_won || 0,
          totalWinnings: response.data.total_winnings || 0,
        });
      }
    } catch (error) {
      console.error("Error fetching profile stats:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, [accessToken]);

  return (
    <div className="container my-5">
      {loading ? (
        <p style={{ textAlign: "center" }}>
          <span className="loader"></span>
        </p>
      ) : (
        <>
          {/* Total Balance */}
          <div className="text-center mb-5">
            <img
              ref={imgRef}
              src={profilePicUrl}
              className="rounded-circle mb-2"
              width="60px"
              height="60px"
              alt="Profile"
              onError={() => {
                if (!imageError) {
                  setImageError(true); // ✅ Ensures it runs only once
                }
              }}
            />
            <h3 className="h2 fw-bold mb-4">{profileData.name}</h3>
            <div className="d-flex justify-content-center gap-3">
              <Link to="/editprofile" className="btn btn-outline-dark">
                <i className="fa fa-user-edit"></i> Edit Profile
              </Link>
              <Link to="/logout" className="btn btn-outline-danger">
                <i className="fas fa-sign-out-alt"></i> Logout
              </Link>
            </div>
          </div>

          {/* Balance Cards */}
          <div className="row text-center mb-4">
            <div className="col-md-4 mb-4">
              <div className="card p-4 shadow-sm h-100 text-center">
                <i className="fas fa-puzzle-piece display-6 mb-3"></i>
                <h6 className="text-secondary">Events Played</h6>
                <h2 className="fw-bold">{profileData.eventsPlayed}</h2>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card p-4 shadow-sm h-100 text-center">
                <i className="fas fa-trophy display-6 mb-3"></i>
                <h6 className="text-secondary">Events Won</h6>
                <h2 className="fw-bold">{profileData.eventsWon}</h2>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card p-4 shadow-sm h-100 text-center">
                <i className="fas fa-rupee-sign display-6 mb-3"></i>
                <h6 className="text-secondary">Total Winnings</h6>
                <h2 className="fw-bold">₹{profileData.totalWinnings}</h2>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MyProfile;
