import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageBreadcrumb from '../components/PageBreadcrumb';
import MyProfile from '../components/MyProfile';

const Profile = () => {
  return (
    <div>
      <Helmet>
        <title>Prediction API - My Profile</title>
        <meta name="description" content="View the details of a single trade on Prediction API." />
      </Helmet>
      <PageBreadcrumb pageName="My Profile" />
        <MyProfile />
    </div>
  );
};

export default Profile;
