import React from "react";
import { Route } from "react-router-dom";
import SiteLayout from "../layouts/SiteLayout";
import HomePage from "../pages/HomePage";
import Category from "../pages/Category";
import SingleTrade from "../pages/SingleTrade";
import TransactionHistory from "../pages/TransactionHistory";
import Profile from "../pages/Profile";
import EditProfile from "../pages/EditProfile";
import Wallet from "../pages/Wallet";
import ProtectedRoute from "../routes/ProtectedRoute"; 
import Recharge from "../pages/Recharge";
import PortfolioPage from "../pages/PortfolioPage";
import EventPortfolioPage from "../pages/EventPortfolioPage";

const SiteRoutes = () => [
  <Route
    key="/"
    path="/"
    element={
      <SiteLayout>
        <HomePage />
      </SiteLayout>
    }
  />,
  <Route
  key="/home"
  path="/home"
  element={
    <SiteLayout>
      <HomePage />
    </SiteLayout>
  }
/>,
  <Route
    key="/category/:id"
    path="/category/:id"
    element={
      <SiteLayout>
        <Category />
      </SiteLayout>
    }
  />,
  <Route
    key="/singletrade/:id"
    path="/singletrade/:id"
    element={
      <SiteLayout>
        <SingleTrade />
      </SiteLayout>
    }
  />,

  

  // ✅ Protect these routes
  <Route key="protected" element={<ProtectedRoute />}>
    <Route
      key="/profile"
      path="/profile"
      element={
        <SiteLayout>
          <Profile />
        </SiteLayout>
      }
    />
    <Route
      key="/editprofile"
      path="/editprofile"
      element={
        <SiteLayout>
          <EditProfile />
        </SiteLayout>
      }
    />,
    <Route
    key="/transactionhistory"
    path="/transactionhistory"
    element={
      <SiteLayout>
        <TransactionHistory />
      </SiteLayout>
    }
  />,

  <Route
    key="/wallet"
    path="/wallet"
    element={
      <SiteLayout>
        <Wallet />
      </SiteLayout>
    }
  />,

<Route
  key="/recharge"
  path="/recharge"
  element={
    <SiteLayout>
      <Recharge />
    </SiteLayout>
  }
/>,

<Route
  key="/portfolio"
  path="/portfolio"
  element={
    <SiteLayout>
      <PortfolioPage />
    </SiteLayout>
  }
/>,

<Route
  key="/portfolio/event/:eventId"
  path="/portfolio/event/:eventId"
  element={
    <SiteLayout>
      <EventPortfolioPage />
    </SiteLayout>
  }
/>,

  </Route>,
];

export default SiteRoutes;
