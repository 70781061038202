import React from 'react';
import { Helmet } from 'react-helmet-async';
import OTPVerificationForm from '../components/Forms/OTPVerificationForm';
const OTPVerification = () => {
  return (
    <div>
      <Helmet>
        <title>Prediction API - OTP Verification</title>
        <meta name="description" content="Explore various categories of events in the Prediction API." />
      </Helmet>
        <OTPVerificationForm />
    </div>
  );
};

export default OTPVerification;
