import React from 'react';
import { Link } from 'react-router-dom';

const TransactionBreadcrumbSection = () => (
  <section className="breadcrumb-section">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <nav style={{ '--bs-breadcrumb-divider': "'>'" }} aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="/wallet">Wallet</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Transaction History</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section>
);

export default TransactionBreadcrumbSection;
