import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';

const MenuSlider = () => {
  const [subcategories, setSubcategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const options = {
    dots: false,
    nav: true,
    autoplay: false,
    margin: 10,
    responsive: {
      0: { items: 3 },
      600: { items: 4 },
      1024: { items: 5 },
      1199: { items: 8 },
      1366: { items: 8 },
    },
  };

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/v1/admin/getSubcategoriesWithLiveQuestions`, {
          method: 'GET',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();

        if (data.status === 1 && Array.isArray(data.subcategories)) {
          setSubcategories(data.subcategories);
        } else {
          setError('Failed to fetch subcategories or data format is incorrect');
        }
      } catch (fetchError) {
        setError('Error fetching subcategories');
        console.error('Fetch error:', fetchError);
      } finally {
        setLoading(false);
      }
    };

    fetchSubcategories();
  }, []);

  if (loading) {
    return <p style={{textAlign:'center'}}>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section className="menu-slider-sec">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {subcategories.length > 0 ? (
              <OwlCarousel className="menu-slider" {...options}>
                {subcategories.map((subcategory) => (
                  <div className="slider-item" key={subcategory.id}>
                    <a href={`/category/${subcategory.id}`}>
                      <img src="assets/images/category1.jpeg" className="img-fluid" alt={subcategory.subcategory} />
                      <span>{subcategory.subcategory}</span>
                    </a>
                  </div>
                ))}
              </OwlCarousel>
            ) : (
              <p>No subcategories available</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MenuSlider;
