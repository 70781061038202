import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import "./style/OrderWidget.css";

const TradeSidebar = ({ event, selectedOption }) => {
  const { accessToken, fetchUserBalance } = useAuth();
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState(""); // Track message type

  // ✅ Set default bid type to "yes"
  const [bidType, setBidType] = useState("yes");

  // ✅ Set initial option for non-polling questions
  const [activeOption, setActiveOption] = useState(null);

  // ✅ Default price (Ensures valid price even if no option is available)
  const [price, setPrice] = useState(6.5);
  const [youPut, setYouPut] = useState(price * quantity);
  const [youGet, setYouGet] = useState(quantity * 10);

  useEffect(() => {
    if (event?.options?.length > 0) {
      setActiveOption(selectedOption || event.options[0]); // ✅ Default to first option for polling
    } else {
      // ✅ For non-polling questions, set default values based on event (Yes selected by default)
      setActiveOption(null);
      setBidType("yes");
      setPrice(event?.yes_point || 6.5);
    }
  }, [event, selectedOption]);

  useEffect(() => {
    if (activeOption) {
      setPrice(bidType === "no" ? activeOption.no_point || 6.5 : activeOption.yes_point || 6.5);
    } else {
      setPrice(bidType === "no" ? event?.no_point || 6.5 : event?.yes_point || 6.5);
    }
  }, [activeOption, bidType]);

  useEffect(() => {
    setYouPut(price * quantity);
    setYouGet(quantity * 10);
  }, [price, quantity]);

  const handleOptionClick = (option) => {
    setActiveOption(option);
    setPrice(bidType === "no" ? option?.no_point || 6.5 : option?.yes_point || 6.5);
  };

  const handleBidTypeChange = (type) => {
    setBidType(type);
    if (activeOption) {
      setPrice(type === "no" ? activeOption.no_point || 6.5 : activeOption.yes_point || 6.5);
    } else {
      setPrice(type === "no" ? event?.no_point || 6.5 : event?.yes_point || 6.5);
    }
  };

  const handlePriceChange = (e) => {
    setPrice(parseFloat(e.target.value) || 0);
  };

  const incrementQuantity = () => {
    setQuantity((prev) => (prev < 5 ? prev + 1 : prev));
  };

  const decrementQuantity = () => {
    setQuantity((prev) => (prev > 1 ? prev - 1 : prev));
  };

  // ✅ **Bid Placement API Call**
  const placeOrder = async () => {
    if (!event) {
      setMessage("Invalid order details");
      setMessageType("danger");
      return;
    }

    if (!accessToken) {
      setMessage("Authorization token is missing.");
      setMessageType("danger");
      return;
    }

    setLoading(true);
    setMessage("");
    setMessageType("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/bids/place`,
        {
          questionId: event.id,
          price: price,
          quantity: quantity,
          bidType: bidType,
          optionId: activeOption?.id || null,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          withCredentials: true,
        }
      );

      if (response.status === 201 || response.status === 200) {
        setMessage("Bid placed successfully!");
        setMessageType("success"); // ✅ Success message
        await fetchUserBalance(accessToken);
      } else {
        setMessage(response.data.message || "Failed to place bid.");
        setMessageType("danger"); // ❌ Error message
      }
    } catch (error) {
      console.error("❌ API Error:", error);
      setMessage(error.response?.data?.message || "Failed to place bid.");
      setMessageType("danger"); // ❌ Error message
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="order-widget order-widget002">
      {/* ✅ Display options for polling questions */}
      {event?.options?.length > 0 && (
        <div className="options-tabs d-flex gap-2 mb-3">
          {event.options.map((option) => (
            <button
              key={option.id}
              className={`option-tab ${activeOption?.id === option.id ? "active" : ""}`}
              onClick={() => handleOptionClick(option)}
              style={{
                background: activeOption?.id === option.id ? "#000" : "transparent",
                borderColor: "#000",
                color: activeOption?.id === option.id ? "#fff" : "#000",
                padding: "10px 15px",
                borderRadius: "4px",
                cursor: "pointer",
                borderWidth: "1px",
                borderStyle: "solid",
                fontSize: "14px",
              }}
            >
              {option.options_str}
            </button>
          ))}
        </div>
      )}

      {/* ✅ Toggle Buttons for Yes/No */}
      <div className="toggle-buttons">
        <button className={`toggle ${bidType === "yes" ? "active" : ""}`} onClick={() => handleBidTypeChange("yes")}>
          Yes ₹{activeOption ? activeOption.yes_point : event?.yes_point || 6.5}
        </button>
        <button className={`toggle ${bidType === "no" ? "active" : ""}`} onClick={() => handleBidTypeChange("no")}>
          No ₹{activeOption ? activeOption.no_point : event?.no_point || 6.5}
        </button>
      </div>

      <div className="price-section">
        <div className="price-label">Price</div>
        <div className="d-flex flex-column">
          <div className="price-controls">
            <input
              type="number"
              className="price-value"
              value={price}
              onChange={handlePriceChange}
              readOnly
            />
          </div>
          {/* <div className="qty-info">{event?.available_qty || 1942} qty available</div> */}
        </div>
      </div>

      <div className="quantity-section">
        <div className="quantity-label">Quantity</div>
        <div className="quantity-controls">
          <button className="decrement" onClick={decrementQuantity}>-</button>
          <span className="quantity-value">{quantity}</span>
          <button className="increment" onClick={incrementQuantity}>+</button>
        </div>
      </div>

      <div className="total-section">
        <div className="you-put">
          ₹{youPut.toFixed(2)}<br />
          <span>You put</span>
        </div>
        <div className="you-get">
          ₹{youGet.toFixed(2)}<br />
          <span>You get</span>
        </div>
      </div>

      {/* <button className="place-order" onClick={placeOrder} disabled={loading}>
        {loading ? "Placing Order..." : "Place Order"}
      </button>

      {message && <p className="order-message">{message}</p>} */}
    
    
    {/* ✅ Loader While Placing Order */}
    {loading ? (
      <p style={{ textAlign: "center" }}>
        <span className="loader"></span>
      </p>
    ) : (
      <button className="place-order" onClick={placeOrder} disabled={loading}>
        Place Order
      </button>
    )}

    {/* ✅ Bootstrap Alert for Success or Error Messages */}
    {message && (
      <div className={`alert alert-${messageType} mt-3`} role="alert">
        {message}
      </div>
    )}
    
    
    </div>
  );
};

export default TradeSidebar;
