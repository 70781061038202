import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

const LoginForm = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);
  const [serverMessage, setServerMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = async (data) => {
    setLoading(true);
    setServerMessage("");

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/send-otp`, {
        mobile: data.mobile,
      });

      navigate("/otpverification", {
        state: {
          mobile: data.mobile,
          message: response.data.message,
          from: location.state?.from || null, // Pass only if redirected from protected page
        },
      });
    } catch (error) {
      console.error("Error sending OTP:", error);
      setServerMessage(error.response?.data?.message || "Failed to send OTP.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="vh-100" style={{ backgroundColor: "#000" }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-xl-10">
            <div className="card" style={{ borderRadius: "1rem" }}>
              <div className="row g-0">
                <div className="col-md-6 col-lg-5 d-none d-md-block">
                  <img src="assets/images/img1.webp" alt="login form" className="img-fluid" style={{ borderRadius: "1rem 0 0 1rem" }} />
                </div>
                <div className="col-md-6 col-lg-7 d-flex align-items-center">
                  <div className="card-body p-4 p-lg-5 text-black">
                    {serverMessage && <p className="text-danger text-center">{serverMessage}</p>}
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <h4 className="fw-bold mb-0 pb-3" style={{ letterSpacing: "1px" }}>Enter your mobile number</h4>
                      <p>We’ll send you an OTP</p>
                      <div className="form-outline mb-4">
                        <div className="enter-number d-flex align-items-center">
                          <span className="form-control-sm">+91</span>
                          <input
                            type="text"
                            className={`form-control form-control-sm ${errors.mobile ? "is-invalid" : ""}`}
                            placeholder="Enter Mobile Number"
                            {...register("mobile", {
                              required: "Mobile number is required",
                              pattern: {
                                value: /^[6-9]\d{9}$/,
                                message: "Enter a valid 10-digit mobile number",
                              },
                            })}
                          />
                        </div>
                        {errors.mobile && <div className="invalid-feedback">{errors.mobile.message}</div>}
                      </div>
                      <div className="pt-1 mb-4">
                        <button type="submit" className="btn btn-dark btn-lg btn-block w-100" disabled={loading}>
                          {loading ? "Sending OTP..." : "GET OTP"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;
