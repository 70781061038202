import React from 'react';

const TradeDetail = ({ questionDetails, loading, activeOption, setActiveOption }) => {
  if (loading) return <p>Loading question details...</p>;
  if (!questionDetails) return <p>Question not found.</p>;


  const formatDate = (isoDate) => {
    if (!isoDate) return "N/A";
    
    return new Date(isoDate).toLocaleString("en-IN", {
      weekday: "long", // Monday
      day: "2-digit",  // 16
      month: "long",   // December
      year: "numeric", // 2024
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,    // 12-hour format
    });
  };

  
  return (
    <div>
      <div className="single-match-details d-flex align-items-center gap-3">
        <div className="match-img">
          <img src="/assets/images/card-thumb.png" className="img-fluid" alt="Match Thumbnail" />
        </div>
        <div className="match-details">
          <h1 className="match-info">{questionDetails.question}</h1>
        </div>
        <div className="add-whislist-icon m-auto">
          <a href="#"><i className="fa fa-plus-circle"></i></a>
        </div>
      </div>

      <div className="overview mb-5">
        <h2 className="overview-title">Stats</h2>
        <div className="card">
          <div className="card-body">
            <h4 className="overview-card-title">Overview</h4>
            <p>{questionDetails.overview}</p>
          </div>
        </div>
      </div>

      <div className="overview">
        <h2 className="about-title">About the event</h2>
        <div className="d-block d-md-flex flex-row align-items-center justify-content-between mb-4">
          <div className="style-event-overview-col">
            <div className="style-event-overview-title">Source of Truth</div>
            <div className="style-event-overview-description">{questionDetails.source_truth}</div>
          </div>
          <div className="style-event-overview-col">
            <div className="style-event-overview-title">Trading started on</div>
            <div className="style-event-overview-description">{formatDate(questionDetails.start_on)}</div>
          </div>
          <div className="style-event-overview-col">
            <div className="style-event-overview-title">Event expires on</div>
            <div className="style-event-overview-description">{formatDate(questionDetails.expires_in)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeDetail;
