import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';

const CategoryTabs = () => {
  const { id: subcategoryId } = useParams(); 
  const [filter, setFilter] = useState('All');
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      let response;
      if (filter === 'All') {
        response = await axios.get(`${serverUrl}/v1/admin/getLiveQuestionsBySubcategory/${subcategoryId}`, {
          withCredentials: true,
        });
      } else {
        response = await axios.get(`${serverUrl}/v1/admin/fetchQuestionsBySubcategoryFilter/${subcategoryId}`, {
          params: {
            filter: filter.toLowerCase(),
            page: 1,
            perPage: 10,
          },
          withCredentials: true,
        });
      }
      setQuestions(response.data.questions || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching questions:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, [filter]);

  return (
    <section className="content-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ul className="nav custom-nav-rounded justify-content-center mb-5" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${filter === 'All' ? 'active' : ''}`}
                  onClick={() => setFilter('All')}
                >
                  All
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${filter === 'Match' ? 'active' : ''}`}
                  onClick={() => setFilter('Match')}
                >
                  <i className="fa fa-trophy mr-2"></i> Match
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${filter === 'Over' ? 'active' : ''}`}
                  onClick={() => setFilter('Over')}
                >
                  <i className="fa fa-bowling-ball mr-2"></i> Over
                </button>
              </li>
            </ul>
            <div className="tab-content">
              {loading ? (
                <p>Loading questions...</p>
              ) : questions.length > 0 ? (
                <div className="row">
                  {questions.map((question) => (
                    <div className="col-md-4 mb-4" key={question.id}>
                      <div className="card events-card">
                        <div className="card-header gap-2">
                          <i className="fa fa-users"></i>
                          <span>{question.trader_count || 0} traders</span>
                        </div>
                        <div className="card-body">
                          <div className="d-flex gap-3">
                            <div className="match-img">
                              <img src="/assets/images/card-thumb.png" className="img-fluid" alt="Event Thumbnail" />
                            </div>
                            <div className="card-details">
                              <p className="match-info">{question.title}</p>
                            </div>
                          </div>
                          <p className="head-to-head">{question.match_info}</p>
                          <div className="btn-group d-flex gap-3" role="group">
                            <Link to={`/singletrade/${question.id}`} className="btn btn-yes">{question.yes_point}</Link>
                            <Link to={`/singletrade/${question.id}`} className="btn btn-no">{question.no_point}</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No questions found for this category.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategoryTabs;
