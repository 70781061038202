import React from 'react';
import { Helmet } from 'react-helmet-async';
import CategoryMenu from '../components/CategoryMenu';
import MenuSlider from '../components/MenuSlider';
import EventsSection from '../components/EventsSection';
const HomePage = () => {
  return (
    <div>
      <Helmet>
        <title>Prediction API - Home</title>
        <meta name="description" content="Welcome to the Prediction API Home Page, where you can find the latest updates and events." />
      </Helmet>

       <CategoryMenu />
       <MenuSlider />
       <EventsSection />
       
    </div>
  );
};

export default HomePage;
