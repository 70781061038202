import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const TradeDetail = () => {
  const { id } = useParams(); // Retrieve question ID from route parameters
  const [questionDetails, setQuestionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    const fetchQuestionDetails = async () => {
      try {
        const response = await axios.get(`${serverUrl}/v1/admin/getQuestionById/${id}`, {
          headers: {
            Authorization: `Bearer ${document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*\=\s*([^;]*).*$)|^.*$/, "$1")}`, // Retrieve token from cookies
          },
          withCredentials: true,
        });

        if (response.data.status === 1) {
          setQuestionDetails(response.data.question);
        } else {
          console.error('Failed to fetch question details:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching question details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestionDetails();
  }, [id, serverUrl]);

  if (loading) return <p>Loading question details...</p>;
  if (!questionDetails) return <p>Question not found.</p>;

  return (
    <section className="content-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="single-match-details d-flex align-items-center gap-3">
              <div className="match-img">
                <img src="/assets/images/card-thumb.png" className="img-fluid" alt="Match Thumbnail" />
              </div>
              <div className="match-details">
                <h1 className="match-info">{questionDetails.question}</h1>
              </div>
              <div className="add-whislist-icon m-auto">
                <a href="#"><i className="fa fa-plus-circle"></i></a>
              </div>
            </div>

            <div className="overview mb-5">
              <h2 className="overview-title">Stats</h2>
              <div className="card">
                <div className="card-body">
                  <h4 className="overview-card-title">Overview</h4>
                  <p>{questionDetails.overview}</p>
                </div>
              </div>
            </div>

            <div className="overview">
              <h2 className="about-title">About the event</h2>
              <div className="d-block d-md-flex flex-row align-items-center justify-content-between mb-4">
                <div className="style-event-overview-col">
                  <div className="style-event-overview-title">Source of Truth</div>
                  <div className="style-event-overview-description">{questionDetails.source_truth}</div>
                </div>
                <div className="style-event-overview-col">
                  <div className="style-event-overview-title">Trading started on</div>
                  <div className="style-event-overview-description">{questionDetails.start_date}</div>
                </div>
                <div className="style-event-overview-col">
                  <div className="style-event-overview-title">Event expires on</div>
                  <div className="style-event-overview-description">{questionDetails.expires_in}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TradeDetail;
